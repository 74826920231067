import axios from 'axios';

function getUserInfoData () {
  return axios({
    url: '/api/auth/sc/getUserInfo',
    method: 'post',
  }).then((res) => {
    if (res.status == '200') {
      return res.data;
    }
  }).catch((err) => {
    throw err;
  })
};


function serviceDownload(paramDate){
  return axios({
    url: '/api/auth/sc/getVerificationCode',
      method: 'get',
      params: { paramDate },
      responseType: "arraybuffer",
    }).then((res) => {
      return 'data:image/png;base64,' + btoa(new Uint8Array(res.data).reduce((data, byte) => data + String.fromCharCode(byte), ''));
  })
}

function getUserDealtCount (data) {
    return axios({
        url: '/api/oa/schoolProcessApply/list',
        method: 'post',
        data: data
    }).then((res) => {
        if (res.status == '200') {
            return res.data;
        } else {
            throw '获取用户待办出错了';
        }
    }).catch((err) => {
        throw err;
    })
}

// 获取钉钉配置
function getDingConfig (data) {
    return axios({
        url: '/api/operate/operateDingConfig/getDingConfigByProjectMark',
        method: 'post',
        data: data
    }).then((res) => {
        if (res.status === 200) {
            return res.data;
        } else {
            throw '获取钉钉配置出错';
        }
    }).catch((err) => {
        throw err;
    })
}

function getNewsNum(data) {
  return axios({
      url: '/api/operate/operateNews/getNewsNum',
      method: 'post',
      data: data
  }).then((res) => {
      if (res.status == '200') {
          return res.data;
      } else {
          throw ' ';
      }
  }).catch((err) => {
      throw err;
  })
}

/**
 * @Description: 获取用户主题config
 * @Author: 武东超
 * @Date: 2024-08-21 10:25:39
 */
function getUserThemeConfig (data) {
    return axios({
      url: '/api/school/schoolUserPreferenceDynamicConfig/getDetail',
      method: 'post',
      data: data
    }).then((res) => {
      if (res.status == '200') {
        return JSON.parse(res.data.data.config);
      };
    }).catch((err) => {
      throw err;
    })
};

/**
 * @Description: 写入用户主题
 * @Author: 武东超
 * @Date: 2024-08-21 11:08:41
 */
function setUserThemeConfig (data) {
    return axios({
      url: '/api/school/schoolUserPreferenceDynamicConfig/save',
      method: 'post',
      data: data
    }).then((res) => {
      if (res.status == '200') {
        return res.data;
      }
    }).catch((err) => {
      throw err;
    })
};
export {
    getUserInfoData,
    serviceDownload,
    getUserDealtCount,
    getDingConfig,
    getNewsNum,
    getUserThemeConfig,
    setUserThemeConfig
}
