import Vue from 'vue'
import Vuex from 'vuex'

import state from './state';
import actions from './actions';
import mutations from './mutations';

/**
 * user 用户信息
 * permission 权限
 * style 布局样式
*/
import user from './modules/user';
import permission from './modules/permission';
import style from './modules/config/style';

Vue.use(Vuex)

export default new Vuex.Store({
  state,
  mutations,
  actions,
  modules: {
    user,
    style,
    permission
  }
})
