let userInfo = {
    hasAuth: false,
    userRouters: [],
    originRouters: [],
    userId: '',
    roleId: '',
    userName: '',
    loginName: '',
	userImg: '',
    schoolId: '',
    isSuper: '',
    schoolLogo: '',
    schoolName: '',
    gradeLevelName: '',
    gradeLevel: '',
    projectMark: '',
    permissions: [],
    templateInfo: [],
    teacherId: '',
    schoolStageList: [],
    visitedViews: [],
    approvalManagementGroupId: '',
    OAQueryData: {},
    slectData:{},
    dealtNum: 0, // 待办数量
    oaManagementListLeftIds: '',
    delItemStatus: false,
    phone: '',
    isTourClass: '',
    reportSchoolName:'',
    addresUKey:'',
    newsNum: 0, // 待办数量
    feedbackNum: 0
}

try {
    if (localStorage.approvalManagementGroupId) {
        userInfo.approvalManagementGroupId = JSON.parse(localStorage.approvalManagementGroupId);
    } else {
        userInfo.approvalManagementGroupId = '';
    }
} catch (e) {

}
try {
    if (localStorage.OAQueryData) {
        userInfo.OAQueryData = JSON.parse(localStorage.OAQueryData);
    }
} catch (e) {
}

try {
    if (localStorage.oaManagementListLeftIds) {
        userInfo.oaManagementListLeftIds = JSON.parse(localStorage.oaManagementListLeftIds);
    }
} catch (e) {};
try {
    if (localStorage.isTourClass) {
        userInfo.isTourClass = JSON.parse(localStorage.isTourClass);
    }
} catch (e) {};

try {
    if (localStorage.projectMark) {
        userInfo.projectMark = JSON.parse(localStorage.projectMark);
    }
} catch (e) {};

export default userInfo
