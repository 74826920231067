import STYLE_CONFIG from '@/config/style';
import THEME_CONFIG from '@/assets/data/theme_options.js';

const state = {
    ...STYLE_CONFIG
};

const mutations = {
    updateStyle (state, payload = THEME_CONFIG[0]) {
        state.brandTheme = payload.brandTheme;
        state.headerActive = payload.headerActive;
        state.headerBcImg = payload.headerBcImg;
        state.layout = payload.layout;
        state.layoutBcImg = payload.layoutBcImg;
        state.mode = payload.mode;
        state.sildBarActive = payload.sildBarActive;
        state.sildBarBc = payload.sildBarBc;
        state.toolbarRightShow = payload.toolbarRightShow;
    }
};

const actions = {
    changeTheme({ commit }, payload = STYLE_CONFIG[0]){
        commit('updateStyle', payload)
    }
}

const getters = {

}

export default {
    state,
    mutations,
    getters,
    actions
};
