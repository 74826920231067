const state = {
    formatRouter: [],
    mixSideRouter: [],
    mixTopRouter: [],
    originRouter: [],
    segmentMenuList: [],
    userPermission: [],
    isSuper: ''
}

const mutations = {
    setUserPrivilegeInfo (state, data) {
        state.formatRouter = data.formatRouter;
        state.mixSideRouter = data.mixSideRouter;
        state.mixTopRouter = data.mixTopRouter;
        state.originRouter = data.originRouter;
        state.segmentMenuList = data.segmentMenuList;
        state.segmentPermList = data.segmentPermList;
        state.userPermission = data.userPermission;
        state.isSuper = data.isSuper;
    }
}

const getters = {
    mixTopRouter: (state) => state.mixTopRouter,
    mixSideRouter: (state) => state.mixSideRouter
}


export default {
    state,
    mutations,
    getters
}
